import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TextButton from "../Core/Buttons/TextButton"
import Pinterest from "../Core/Buttons/Pinterest"
import { getCategoryLink, getArticleLink } from "../../services/blog/link"
import { getCategoryTitle } from "../../services/blog/category"

const Card = styled.div`
  border: 3px solid ${colors.charcoal};
  padding: 19px;
  margin-bottom: 40px;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  @media (min-width: ${breakpoints.md}) {
    padding: 20px 20px 30px;
    flex: 1;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 60px;
  }
`

const CategoryContainer = styled.div`
  margin-top: 20px;
  a {
    text-decoration: none;
  }
`

const CategoryLink = styled.div`
  ${fonts.sofiaProBold};
  color: ${colors.charcoal};
  font-size: 10px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  padding: 7px 10px;
  box-sizing: border-box;
  border: 1px solid ${colors.charcoal};
  display: flex;
  min-width: 143px;
  justify-content: center;
  align-items: center;
  width: max-content;
  transition: all 0.25s;

  :hover {
    background: ${colors.purple};
    border-color: ${colors.purple};
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 30px;
    min-width: 140px;
  }
`

const ArticleTitle = styled.div`
${fonts.sofiaProBold};
  margin-top: 20px;
  font-size: 18.8px;
  line-height: 1.3;

  a {
    color: inherit;
    text-decoration: none;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 20px;
  }
`

const ArticleSnippet = styled.div`
  margin-top: 9px;
  font-size: 15px;
  line-height: 1.38;
  padding-right: 8px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 14px;
  }
`

const LinkContainer = styled.div`
  margin-top: 19px;
  min-width: 100%;
  a {
    font-size: 11px;
  }
  @media (min-width: ${breakpoints.sm}) {
    a {
      font-size: 12px;
    }
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 23px;
  }
`

const ArticleImageContainer = styled.div`
  padding-bottom: 70%;
  position: relative;
`

const ArticleImage = styled(GatsbyImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 0;
`

const ArticleCard = (props) => {
  const { featured_image, category, title, previewSnippet, slug } = props
  const [showPtSave, setShowPtSave] = useState(false)
  let categoryTitle = getCategoryTitle(category)
  const articleLink = getArticleLink(slug, category)
  const pinType = "buttonPin"
  const description = `&description=${title}`
  const mediaUrl = `&media=https://${featured_image?.file?.url}`

  const to = `https://www.pinterest.com/pin/create/button/?${mediaUrl}${description}`

  return (
    <Card>
      <div>
        <ArticleImageContainer
          onMouseOver={() => setShowPtSave(true)}
          onMouseLeave={() => setShowPtSave(false)}
          onTouchStart={() => setShowPtSave(true)}
          onTouchEnd={() => setShowPtSave(false)}
        >
          <a href={articleLink} title={`Article - ${title}`}>
            <ArticleImage image={getImage(featured_image)} alt={title  || ""} />
          </a>
          <Pinterest visible={showPtSave ? 1 : 0} pinType={pinType} href={to} />
        </ArticleImageContainer>
        <CategoryContainer>
          <a href={getCategoryLink(category)}>
            <CategoryLink>{categoryTitle}</CategoryLink>
          </a>
        </CategoryContainer>
        <ArticleTitle>
          <a href={articleLink}>{title} </a>
        </ArticleTitle>
        {previewSnippet && <ArticleSnippet
          dangerouslySetInnerHTML={{
            __html: previewSnippet.childMarkdownRemark.html,
          }}
        />}
      </div>
      <LinkContainer>
        <TextButton href={articleLink}>Keep reading</TextButton>
      </LinkContainer>
    </Card>
  )
}

export default ArticleCard
