import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Containers/ContentContainer"
import { getBlogLink } from "../../services/blog/link"

const IntroContainer = styled.div`
  margin: 40px auto 0;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 153px;
    padding: 0 10px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 30px;
  }
`

const Contents = styled.div`
  position: relative;
`

const TitleSection = styled.div`
  text-align: center;
  max-width: 520px;
  margin: auto;
  padding: 81px 0 60px;
  @media (min-width: ${breakpoints.md}) {
    padding: 98px 0 60px;
  }
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  font-size: 48px;
  letter-spacing: -0.4px;
  line-height: 1.1;
  @media (min-width: ${breakpoints.md}) {
    font-size: 46px;
    letter-spacing: normal;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 52px;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 60px;
  }
`

const IntroCopy = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.44;

  @media (min-width: ${breakpoints.md}) {
    line-height: 1.33;
  }
`

const BlogLink = styled.a`
  ${fonts.sofiaPro};
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.charcoal};
  transition: color 0.4s;
  text-decoration: none;
  svg {
    width: 13px;
    height: 13px;
    margin-right: 11px;
    transition: all 0.4s;
  }
  span {
    line-height: .5;
  }
  :hover {
    color: ${colors.purple};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-left: 15px;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-left: -5px;
  }
`

const StyledSvg = styled.svg`
  stroke: ${props => props.hover ? colors.purple : colors.charcoal};
`

const ArrowIcon = ({hover}) => {
  return (
    <StyledSvg xmlns="http://www.w3.org/2000/StyledSvg" viewBox="0 0 19 17" hover={hover}>
      <path
        vectorEffect="non-scaling-stroke"
        d="M18 8.4H3"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        vectorEffect="non-scaling-stroke"
        fill="none"
        strokeWidth="1.5"
        d="M10 15.9L2 8.4l8-7.5"
      />
    </StyledSvg>
  )
}

const Intro = (props) => {
  const { introText, title } = props
  const [hover, setHover] = useState(false)
  return (
    <IntroContainer>
      <ContentContainer>
        <Contents>
          <BlogLink href={getBlogLink()} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <ArrowIcon hover={hover} />
            <span>Explore all topics</span>
          </BlogLink>
          <TitleSection>
            <Title>{title}</Title>
            <IntroCopy>{introText}</IntroCopy>
          </TitleSection>
        </Contents>
      </ContentContainer>
    </IntroContainer>
  )
}

export default Intro
