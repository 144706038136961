import React, {Suspense} from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Intro from "../components/Category/Intro"
import ArticleList from "../components/Blog/ArticleList"
import styled from "styled-components"
import Fallback from "../components/Core/Fallback"



const QuizCtaBlock = React.lazy(() => import("../components/Core/QuizCtaBlock"))


const CategoryContainer = styled.div`
  max-width: 1920px;
  margin: auto;
`

const CategoryTemplate = (props) => {
  const path = props.location.pathname
  const { page, articles } = props.data
  const { metaTitle, metaDescription, cta } = page
  const isSSR = typeof window === "undefined"

  return (
    <Layout transparentHeader={true}>
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
      />
      <CategoryContainer>
        <Intro {...page} />
        <ArticleList articles={articles.nodes} show={9} article/>
      </CategoryContainer>
      {!isSSR && <Suspense fallback={<Fallback/>}><QuizCtaBlock {...cta} /></Suspense>}
    </Layout>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryTemplateQuery($slug: String!) {
    page: contentfulCategory(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      title
      introText
      cta {
        ... quizCalloutFragment
      }
    }
    articles: allContentfulPost(
      filter: { category: { eq: $slug } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      nodes {
        ...articleCardFragment
      }
    }
  }
`
