import React from "react"
import styled from "styled-components"

const PinterestSave = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.4s ease-in;
  pointer-events: none;

  a {
    width: 30px !important;
    height: 30px !important;
    background-size: 30px 30px !important;
    pointer-events: auto;
  }
`

const Pinterest = ({ href, visible, pinType }) => {
  return (
    <PinterestSave visible={visible ? 1 : 0}>
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        data-pin-do={pinType}
        data-pin-round={true}
      />
    </PinterestSave>
  )
}

export default Pinterest
