import React, { useState } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TextButton from "../Core/Buttons/TextButton"
import { getCategoryLink, getArticleLink } from "../../services/blog/link"
import { Col } from "styled-bootstrap-grid"
import { getCategoryTitle } from "../../services/blog/category"
import Lottie from "lottie-react-web"
import featuredAnimation from "../../resources/animation/featured-blog.json"
import Pinterest from "../Core/Buttons/Pinterest"

const ArticleCard = styled.div`
  border: 3px solid ${colors.black};
  padding: 20px 20px 34px;
  margin-bottom: 40px;
  @media (min-width: ${breakpoints.md}) {
    padding: 20px;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 60px;
  }
`

const ImageContainer = styled.div`
  padding-bottom: 70%;
  margin-bottom: 20px;
  position: relative;
  height: 100%;
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    min-width: 44.44444vw;
    max-width: 44.44444vw;
    margin-bottom: 0;
    padding-bottom: 0;
    height: inherit;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding-bottom: 40.7%;
    height: 100%;
  }
  @media (min-width: 1920px) {
    min-width: 950px;
    max-width: 950px;
  }
`

const ArticleCardRow = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`

const ArticleImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  position: absolute !important;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
    position: relative !important;
  }
  @media (min-width: ${breakpoints.xl}) {
    position: absolute !important;
  }
`

const ArticleContent = styled.div`
  @media (min-width: ${breakpoints.md}) {
    padding-left: 4.16667vw;
  }
`

const LinkRow = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: 45px;
  }
`

const CategoryLink = styled.a`
  ${fonts.sofiaProBold};
  color: ${colors.charcoal};
  font-size: 13px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 7px 4px;
  box-sizing: border-box;
  border: 1px solid ${colors.charcoal};
  display: flex;
  max-width: 140px;
  min-width: 140px;
  justify-content: center;
  align-items: center;
  width: max-content;
  transition: all 0.25s;
  margin-right: 10px;

  :hover {
    background: ${colors.purple};
    border-color: ${colors.purple};
  }

  @media (min-width: ${breakpoints.xl}) {
    min-width: 180px;
    min-height: 40px;
  }
`

const Featured = styled.div`
  ${fonts.sofiaProBold};
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 10px 20px 10px 0;

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    top: 5px;
    right: 0;
  }
`

const ArticleTitle = styled.div`
  ${fonts.garamondSemiBold};
  font-size: 28px;
  line-height: 1.1;
  @media (min-width: ${breakpoints.lg}) {
    font-size: 35px;
    line-height: 1.16;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 40px;
    line-height: 1.3;
  }
`

const Preview = styled.div`
  padding-right: 8px;
  font-size: 18px;
  line-height: 1.44;
  margin-top: 12px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 21px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 25px;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 40px;
  }
`
const ArticleDetail = styled.div`
  @media (min-width: ${breakpoints.md}) {
    padding-right: 5.55556vw;
    padding-bottom: 23px;
  }
`

const StyledCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    height: 100%;
    height: inherit;
    align-items: center;
  }
`

const FeaturedCircle = styled.div`
  position: absolute;
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
    width: 140px;
    top: 0;
    right: 5px;
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 180px;
    top: -5px;
    right: -5px;
  }
`
const FeaturedArticleCard = (props) => {
  const { category, featured_image: image, previewSnippet, slug, title } = props
  let categoryTitle

  const [showPtSave, setShowPtSave] = useState(false)

  if (category) {
    categoryTitle = getCategoryTitle(category)
  }

  const pinType = "buttonPin"
  const description = `&description=${title}`
  const mediaUrl = `&media=https://${image?.file?.url}`

  const to = `https://www.pinterest.com/pin/create/button/?${mediaUrl}${description}`

  return (
    <ArticleCard>
      <ArticleCardRow>
        <ImageContainer
          onMouseOver={() => setShowPtSave(true)}
          onMouseLeave={() => setShowPtSave(false)}
          onTouchStart={() => setShowPtSave(true)}
          onTouchEnd={() => setShowPtSave(false)}
        >
          <ArticleImage image={getImage(image)} alt={title || ""} />
          <Pinterest visible={showPtSave ? 1 : 0} pinType={pinType} href={to} />
        </ImageContainer>
        <StyledCol>
          <ArticleContent>
            <LinkRow>
              <CategoryLink href={getCategoryLink(category)}>
                {categoryTitle}
              </CategoryLink>
              <Featured>Featured</Featured>
              <FeaturedCircle>
                <Lottie
                  options={{
                    animationData: featuredAnimation,
                    loop: false,
                  }}
                />
              </FeaturedCircle>
            </LinkRow>
            <ArticleDetail>
              <ArticleTitle>{title}</ArticleTitle>
              <Preview
                dangerouslySetInnerHTML={{
                  __html: previewSnippet.childMarkdownRemark.html,
                }}
              />
              <ButtonContainer>
                <TextButton href={getArticleLink(slug, category)}>
                  Keep Reading
                </TextButton>
              </ButtonContainer>
            </ArticleDetail>
          </ArticleContent>
        </StyledCol>
      </ArticleCardRow>
    </ArticleCard>
  )
}

export default FeaturedArticleCard
