const BLOG_BASE_PATH = '/learn/';

function getBlogLink() {
  return BLOG_BASE_PATH;
}

function getCategoryLink(slug) {
  return BLOG_BASE_PATH + slug + "/";
}

function getArticleLink(slug, category) {
  return getCategoryLink(category) + slug
}

module.exports = {
  getBlogLink: getBlogLink,
  getCategoryLink: getCategoryLink,
  getArticleLink: getArticleLink
};
