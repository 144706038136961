import React, { useState } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import ArticleCard from "../Core/ArticleCard"
import OnClickButton from "../Core/Buttons/OnClickButton"
import FeaturedArticleCard from "./FeaturedArticleCard"

const ArticleListContainer = styled.div`
  padding: 0 25px;
  max-width: 1920px;
  margin: auto;
  margin-bottom: 120px;

  @media (min-width: ${breakpoints.md}) {
    padding: 0 40px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 60px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-column-gap: 40px;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-column-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${breakpoints.xl}) {
    grid-column-gap: 60px;
  }
`

const ButtonCotainer = styled.div``

const ArticleList = (props) => {
  const { featured, articles, show, learn } = props
  const [showMore, setShowMore] = useState(show)

  let sortedArticles = articles

  if (learn) {
    sortedArticles = articles.sort((a, b) => {
      if (a.position === b.position) {
        return 0
      }
      // nulls sort after anything else
      else if (a.position === null) {
        return 1
      } else if (b.position === null) {
        return -1
      }
      // if descending, highest sorts first
      else {
        return a.position < b.position ? 1 : -1
      }
    })

  }

  let filteredArticles = sortedArticles.filter(
    (article) => article.slug !== featured?.slug
  )

  return (
    <ArticleListContainer>
      {featured && <FeaturedArticleCard {...featured} />}
      <Grid>
        {filteredArticles.map((article, index) => {
          return index + 1 <= showMore ? (
            <React.Fragment key={index}>
              <ArticleCard {...article} />
            </React.Fragment>
          ) : null
        })}
      </Grid>
      {showMore < filteredArticles.length && (
        <ButtonCotainer>
          <OnClickButton onClick={() => setShowMore(showMore + show)}>
            Load More
          </OnClickButton>
        </ButtonCotainer>
      )}
    </ArticleListContainer>
  )
}

export default ArticleList
